import { ChangeEvent, ReactElement, forwardRef } from 'react';

interface Props {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  max?: number | string | undefined;
  maxLength?: number | undefined;
  min?: number | string | undefined;
  minLength?: number | undefined;
  placeholder?: string;
  type?: string;
  value: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  bottomText?: string;
  id?: string;
  name?: string;
  onClick?: () => void;
}

export const TextInput = forwardRef<HTMLInputElement, Props>(
  (props = { value: '', disabled: false, type: 'text' }, ref) => {
    return (
      <div
        className={props.className}
        onClick={() => props.onClick && props.onClick()}
      >
        <label className="block m-0">
          {props.label && (
            <span className="text-sm block text-gray-500 mb-1">
              {props.label}
            </span>
          )}
          <div className="relative">
            {!!props.leftIcon && (
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                {props.leftIcon}
              </div>
            )}
            <input
              ref={ref}
              id={props.id}
              name={props.name}
              autoFocus={props.autoFocus}
              className={[
                'form-input block w-full rounded-md shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none',
                props.errorMessage
                  ? 'border-red-500 bg-red-50'
                  : 'border-gray-300 bg-white',
                !!props.leftIcon ? 'pl-10' : '',
              ].join(' ')}
              value={props.value}
              disabled={props.disabled}
              type={props.type}
              placeholder={props.placeholder}
              max={props.max}
              maxLength={props.maxLength}
              min={props.min}
              minLength={props.minLength}
              onChange={(event) => props.onChange && props.onChange(event)}
              onFocus={(event) => props.onFocus && props.onFocus()}
              onBlur={(event) => props.onBlur && props.onBlur()}
            />
            {props.bottomText && (
              <div className="text-xs mt-1">{props.bottomText}</div>
            )}
          </div>
        </label>
        {props.errorMessage && (
          <span className="text-red-500 text-xs">
            {props.errorMessage}
          </span>
        )}
      </div>
    );
  },
);

export default TextInput;
